import Vue from 'vue/dist/vue.esm';
import axiosSetup from '../sharedteamwebsite/axiosSetup';
import validate from './config/validate.js';

import './styles/tailwind.css';
import './styles/transitions.css';
import '../sharedteamwebsite/styles/donations.scss';
import './styles/ocho.scss';
import './styles/thedam.scss';
import ApiService from '../wrestling/api_service.js';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import 'fullcalendar';

import BoosterTabs from './booster-tabs.vue';
import { currencyMix } from '../shared/currency_mix';
import DonorBox from '../sharedteamwebsite/donor_box.vue';
import FundraiserCtaButton from './fundraiser-cta-button.vue';
import ProgressBar from './progress-bar.vue';
import PublicCalendar from '../sharedteamwebsite/public_calendar.vue';
import Spinner from './icons/spinner.vue';
import SwalEntry from './swal-entry.vue';
import TailwindCalendar from './tailwind-calendar.vue';
import TailwindContactForm from './tailwind-contact-form.vue';
import TailwindFlyout from './tailwind-flyout.vue';
import TailwindNav from './tailwind-nav.vue';
import TailwindPaginatedList from './tailwind-paginated-list.vue';

Sentry.init({
  dsn: 'https://4b96b973744b4bf2ab5f5921513d01ca@sentry.io/256247',
  integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })],
  environment: `${process.env.WIQ_ENV}`,
});

document.addEventListener('DOMContentLoaded', () => {
  // Setup the axios and vee-validate rules
  axiosSetup();
  validate();

  const api = new ApiService();
  Object.defineProperties(Vue.prototype, {
    $apiService: {
      get() {
        return api;
      },
    },
  });

  const app = new Vue({
    el: '#custom-website-vue-wrapper',
    mixins: [currencyMix],
    components: {
      BoosterTabs,
      DonorBox,
      FundraiserCtaButton,
      ProgressBar,
      PublicCalendar,
      Spinner,
      SwalEntry,
      TailwindCalendar,
      TailwindContactForm,
      TailwindNav,
      TailwindFlyout,
      TailwindPaginatedList,
    },
    data: {
      transitions: {},
    },
    mounted() {
      Array.from(document.querySelectorAll('.transition-init')).forEach(function(el) {
        el.classList.remove('transition-init');
      });
    },
    methods: {
      toggleTransition(id) {
        if (!this.transitions[id]) {
          this.$set(this.transitions, id, true);
        } else {
          this.$set(this.transitions, id, !this.transitions[id] || false);
        }
      }
    }
  });
});
